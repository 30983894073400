@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");
.{
  padding: 0% !important;
  font-display:swap
}

.custom-shape-divider-bottom-1649357376 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }
  
  .custom-shape-divider-bottom-1649357376 svg {
    position: relative;
    display: block;
    width: 25rem;
    height: 100px;
    bottom: 95px;
    left: 51rem;
  }
  
  .custom-shape-divider-bottom-1649357376 .shape-fill {
    fill: #c5c5c5;
  }
  